*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: green;
  border-radius: 5px;
}

h1{
  color: #21212195;
}
